import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { Row, Col, Form, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalFooter, ModalBody, FormFeedback } from 'reactstrap'
import formStyles from '../components/styles/form.module.css'
import { handleRefer } from '../services/api';
import { isLoggedIn, logout, isAuthenticated } from "../services/auth"
import { PulseLoader } from 'react-spinners';
import { navigate } from 'gatsby'
import validator from 'validator'
import classnames from 'classnames'
import { handleMFtrigger, handleMFauth } from '../services/auth'
import Amplify, { Auth, Cache, Hub, Logger, API } from "aws-amplify";

const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false

export default class ReferForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            name: "",
            referee: "",
            message: "",
            modal: false,
            loading: false,
            validateForm: false,
            captcha: null,
            isLoading: false,

            emailValid: false,
            nameValid: true,
            refereeValid: false,
            messageValid: false,
            otp: "",
            mfavalid: true,
            mfcounter: 0,
            locationObj : this.props.location ? this.props.location.state : null
        };
        this.modal = this.modal.bind(this)
        
    }

    componentDidMount() {
        this.setState({
            name: !user ? "" : user.userData.attributes.name,
            email: !user ? "" : user.userData.attributes.email
        })
        let { locationObj } = this.state
        let netsclickPage  = locationObj ? locationObj.netsClick : false 

        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        if (Cache.getItem("ma") == 1 && Cache.getItem("otpToken")) {

            if(netsclickPage){

                navigate(`/product/netsclick/requestor`)
            } else {

                navigate(`/account/application/`)
            }
          
            // if(typeof window !== "undefined"){
            //     window.location.reload()
            // }
        } else {
            if (Cache.getItem("matrigger") == 0) {
                handleMFtrigger(user.userData.attributes.email)
            }
        }
    }

    resetState() {
        this.setState({
            email: "",
            name: !user ? "" : user.userData.attributes.email,
            referee: "",
            message: "",
            modal: false,
            loading: false,
            validateForm: false,
            captcha: null,
            isLoading: false,

            emailValid: false,
            nameValid: true,
            refereeValid: false,
            messageValid: false,
            mfavalid: true,
            otp: ""
        })
    }

    modal() {
        this.setState({
            modal: !this.state.modal
        })
    }

    handleChange = event => {
        const name = event.target.id;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, () => {
            // this.validateInput(name, value)
        })
    }

    handleSubmit = async () => {

        this.setState({
            isLoading: true
        })
        await handleMFauth({
            "email": user.userData.attributes.email,
            "token": this.state.otp
        }).then((res) => {

            if (Cache.getItem("ma") == 0) {
                //alert("Invalid OTP")
                this.state.mfcounter = this.state.mfcounter + 1
                this.setState({
                    isLoading: false,
                    mfavalid: false
                })

                if (this.state.mfcounter == 5) {
                    logout(() => navigate(`/signin/`))
                }

            } else {
                let { locationObj } = this.state
                let netsclickPage  = locationObj ? locationObj.netsClick : false 
                if(netsclickPage){

                    navigate(`/product/netsclick/requestor`)
                } else {
    
                    navigate(`/account/application/`)
                }
            }
        })
    }

    render() {
        return (
            <Row className="row">
                <Col sm={{
                    size: 12
                }}
                    md={{
                        size: 8,
                        offset: 2
                    }}
                >
                    <Form
                        onSubmit={this.handleSubmit}
                        className={[formStyles.referContainer].join(' ')}
                    >
                        <h3 style={{
                            fontWeight: 'bolder'
                        }}>
                            Email Authentication
                </h3>
                        <p>An email has been sent to you with a "One Time Password". <br />Please enter it below to continue.</p>                        <FormGroup>
                            <Label for="otp">One Time Password</Label>
                            <Input
                                id="otp"
                                type="text"
                                title="OTP"
                                name="otp"
                                value={this.state.otp.value}
                                onChange={this.handleChange}
                                invalid={!this.state.mfavalid}
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            />
                            <FormFeedback invalid>
                                Please enter a valid OTP.
                    </FormFeedback>
                        </FormGroup>                        <Button
                            block
                            color='primary'
                            disabled={this.state.isLoading}
                            onClick={(event) => {
                                console.log(this.state.otp)
                                this.handleSubmit()
                            }}
                        >
                            {"Submit"}
                        </Button>
                        <Button
                            block
                            color='secondary'
                            disabled={this.state.isLoading}
                            onClick={(event) => {
                                handleMFtrigger(user.userData.attributes.email)
                            }}
                        >
                            {"Resend OTP"}
                        </Button>
                    </Form>
                </Col>
            </Row>
        )
    }
}

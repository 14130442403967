import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Mfauth from "../../containers/mfauth";
import Layout from "../../components/layout"
import { Container } from 'reactstrap'
import generalStyles from '../../components/styles/general.module.css'

export default class mfauth extends Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <Layout>
        <div className={generalStyles.containerBackground}>
          <Container
            style={{
              padding: '2rem 2rem 1rem',
              minHeight: '75vh'
            }}
          >
            <Mfauth location={this.props.location} />
          </Container>
        </div>
      </Layout>
    )
  }
}
